import {onLoad} from "../base/onLoad";
import Macy from "macy";

onLoad(() => {
    const macyContainer = document.querySelector('#macy-container');

    if(!macyContainer) return;

    const macyInstance = new Macy({
        container: macyContainer,
        columns: 2,
        margin: 20,
        breakAt: {
            767: 1
        }
    });

    window.addEventListener('resize', () => {
        macyInstance.recalculate();
    });
});