import {onLoad} from "../base/onLoad";
import Splide from "@splidejs/splide";

onLoad(() => {
    const element = document.querySelector('.klanten-slider');

    if(!element) return;

    const splideInstance = new Splide(element, {
        type: 'loop',
        perPage: 4,
        gap: '4rem',
        arrows: false,
        autoplay: true,
        interval: 2500,

        breakpoints: {
            950: {
                perPage: 3,
                gap: '2rem'
            },
            768: {
                perPage: 2,
                gap: '2rem'
            }
        }
    });

    splideInstance.mount();
});
