import {onLoad} from "../base/onLoad";

onLoad(() => {
    const faqContainers = document.querySelectorAll('.faq-container');
    faqContainers.forEach(faqContainer => {
        new FaqContainer(faqContainer);
    });
});

class FaqContainer {
    constructor(element) {
        this.container = element;
        this.faqElements = this.container.querySelectorAll('.faq-item');
        this.init();
    }

    closeAll() {
        this.faqElements.forEach(faqElement => {
            faqElement.classList.remove('open');
        });
    }

    open(faqElement) {
        if(!faqElement) return;

        this.closeAll();
        faqElement.classList.add('open');
    }

    toggle(faqElement) {
        if(faqElement.classList.contains('open')) {
            faqElement.classList.remove('open');
        } else {
            this.closeAll();
            this.open(faqElement);
        }
    }

    init() {
        this.faqElements.forEach(faqElement => {
            const header = faqElement.querySelector('.faq-item-header');

            header.addEventListener('click', () => {
                this.toggle(faqElement);
            });
        });

        this.closeAll();

        const firstFaqElement = this.faqElements[0] || null;
        this.open(firstFaqElement);
    }
}